import { PRODUCT_CODES } from '_constants/products';
import { PROCESSING_CODES } from '_constants/processing';
import { ROUTES } from '_constants/routes';
import { STORAGE_KEYS } from '_constants/storage';
import { restoreLocalValue } from '_helpers/storage';
import { getMaxSetOfProductsFromBankingBundle } from '_helpers/products';

export const createOrderBody = (
  activeProductCode,
  businessInfo = {},
  packageInfo = {}
) => {
  const products = [];

  packageInfo?.products.forEach((productCode) => {
    const product = {};

    if (productCode === activeProductCode) {
      product.code = productCode;
      product.organizedState = businessInfo.organizedState;

      if (businessInfo.step > 1) {
        product.companyName = businessInfo.tradeName;
        product.designator = businessInfo.designator;
      }

      if (businessInfo.step > 2) {
        product.activityPrimaryActivity = businessInfo.activityPrimaryActivity;
        product.activitySpecificProducts =
          businessInfo.activitySpecificProducts;

        if (businessInfo.productDescription) {
          product.productDescription = businessInfo.productDescription
            .replace(/[\r\n]+/gm, ' ')
            .replace(/\s{2,}/g, ' ')
            .trim();
        }

        if (businessInfo.productExtraQuestion1) {
          product.productExtraQuestion1 = businessInfo.productExtraQuestion1;
        }

        if (businessInfo.productExtraQuestion2) {
          product.productExtraQuestion2 = businessInfo.productExtraQuestion2;
        }

        if (businessInfo.productExtraQuestion3) {
          product.productExtraQuestion3 = businessInfo.productExtraQuestion3;
        }

        if (businessInfo.productExtraQuestion4) {
          product.productExtraQuestion4 = businessInfo.productExtraQuestion4;
        }
      }

      if (businessInfo.step > 3) {
        product.corporateAddress = {
          address1: businessInfo.physicalAddressAddress.trim(),
          address2: businessInfo.physicalAddressAddress2?.trim() || null,
          city: businessInfo.physicalAddressCity.trim(),
          state: businessInfo.physicalAddressState,
          zip: businessInfo.physicalAddressZip,
          county: businessInfo.physicalAddressCounty || null,
          country: 'US',
        };

        if (businessInfo.isMailingAddressSame) {
          product.mailingAddress = {
            address1: businessInfo.physicalAddressAddress.trim(),
            address2: businessInfo.physicalAddressAddress2?.trim() || null,
            city: businessInfo.physicalAddressCity.trim(),
            state: businessInfo.physicalAddressState,
            zip: businessInfo.physicalAddressZip,
            county: businessInfo.physicalAddressCounty || null,
            country: 'US',
          };
        } else {
          product.mailingAddress = {
            address1: businessInfo.mailingAddressAddress.trim(),
            address2: businessInfo.mailingAddressAddress2?.trim() || null,
            city: businessInfo.mailingAddressCity.trim(),
            state: businessInfo.mailingAddressState,
            zip: businessInfo.mailingAddressZip,
            county: businessInfo.mailingAddressCounty || null,
            country: 'US',
          };
        }
      }

      if (businessInfo.step > 4) {
        product.managedByMembers = businessInfo.managedByMembers;
        product.managedByManagers = !businessInfo.managedByMembers;

        if (businessInfo.managedByMembers) {
          product.members = businessInfo.members?.map((item) => ({
            ...item,
            firstName: item?.firstName?.trim() || null,
            middleName: item?.middleName?.trim() || null,
            lastName: item?.lastName?.trim() || null,
            name: null,
            email: null,
            phone: null,
          }));
        } else {
          product.members = businessInfo.managers?.map((item) => ({
            ...item,
            firstName: item?.firstName?.trim() || null,
            middleName: item?.middleName?.trim() || null,
            lastName: item?.lastName?.trim() || null,
            name: null,
            email: null,
            phone: null,
          }));
        }

        product.owner = {
          firstName: businessInfo.representativeFirstName.trim(),
          middleName: businessInfo.representativeMiddleName?.trim() || null,
          lastName: businessInfo.representativeLastName.trim(),
          suffixName: businessInfo.representativeSuffix || null,
          title: businessInfo.representativeTitle || null,
        };

        product.principalOfficer = {
          firstName: businessInfo.representativeFirstName.trim(),
          middleName: businessInfo.representativeMiddleName?.trim() || null,
          lastName: businessInfo.representativeLastName.trim(),
          suffixName: businessInfo.representativeSuffix || null,
          countryOfCitizenship: 'US',
          email: businessInfo.customerEmail,
          phone: businessInfo.customerPhone.replace(/\D/g, ''),
          address: {
            address1: businessInfo.physicalAddressAddress.trim(),
            city: businessInfo.physicalAddressCity.trim(),
            state: businessInfo.physicalAddressState,
            zip: businessInfo.physicalAddressZip,
            country: 'US',
          },
        };
      }

      if (businessInfo.step > 5) {
        product.acceptCreditCardPayments =
          businessInfo.acceptCreditCardPayments;
        product.questionsFirstLLC = businessInfo.questionsFirstLLC;
        product.questionsHaveEmployee = businessInfo.questionsHaveEmployee;
        product.questionsStartedDoingBusiness =
          businessInfo.questionsStartedDoingBusiness;
      }
    } else if (productCode === PRODUCT_CODES.incEIN) {
      product.code = productCode;
      product.legalName = businessInfo.tradeName;
      product.organizedState = businessInfo.organizedState;

      product.numberOfMembers = businessInfo.managedByMembers
        ? businessInfo.members.length + 1
        : businessInfo.managers.length + 1;

      product.owner = {
        firstName: businessInfo.representativeFirstName.trim(),
        middleName: businessInfo.representativeMiddleName?.trim() || null,
        lastName: businessInfo.representativeLastName.trim(),
        suffixName: businessInfo.representativeSuffix || null,
        title: businessInfo.representativeTitle,
      };

      product.corpAddress = {
        address1: businessInfo.physicalAddressAddress.trim(),
        address2: businessInfo.physicalAddressAddress2?.trim() || null,
        city: businessInfo.physicalAddressCity.trim(),
        state: businessInfo.physicalAddressState,
        zip: businessInfo.physicalAddressZip,
        county: businessInfo.physicalAddressCounty || null,
        country: 'US',
      };

      product.activityPrimaryActivity = businessInfo.activityPrimaryActivity;
      product.activitySpecificProducts = businessInfo.activitySpecificProducts;

      if (businessInfo.productDescription) {
        product.productDescription = businessInfo.productDescription
          .replace(/[\r\n]+/gm, ' ')
          .replace(/\s{2,}/g, ' ')
          .trim();
      }

      if (businessInfo.productExtraQuestion1) {
        product.productExtraQuestion1 = businessInfo.productExtraQuestion1;
      }

      if (businessInfo.productExtraQuestion2) {
        product.productExtraQuestion2 = businessInfo.productExtraQuestion2;
      }

      if (businessInfo.productExtraQuestion3) {
        product.productExtraQuestion3 = businessInfo.productExtraQuestion3;
      }

      if (businessInfo.productExtraQuestion4) {
        product.productExtraQuestion4 = businessInfo.productExtraQuestion4;
      }
    } else if (productCode === PRODUCT_CODES.incRegisterTrademark) {
      product.code = productCode;
      product.trademarkType = 'NAME';
      product.markDesc = businessInfo.tradeName;

      product.individual = {
        firstName: businessInfo.representativeFirstName.trim(),
        lastName: businessInfo.representativeLastName.trim(),
        email: businessInfo.customerEmail,
        phone: businessInfo.customerPhone.replace(/\D/g, ''),
        address: {
          address1: businessInfo.physicalAddressAddress.trim(),
          city: businessInfo.physicalAddressCity.trim(),
          state: businessInfo.physicalAddressState,
          zip: businessInfo.physicalAddressZip,
          country: 'US',
        },
      };

      product.organization = {
        usBased: true,
        name: businessInfo.tradeName,
        type: 'LLC',
        stateOrCountryOfFormation: businessInfo.organizedState,
        position: businessInfo.representativeTitle,
      };
    } else if (productCode === PRODUCT_CODES.incTrademarkBasicSearch) {
      product.code = productCode;
      product.searchTermType = 'NAME';
      product.text = businessInfo.tradeName;
    } else if (productCode === PRODUCT_CODES.incTrademarkMonitoring) {
      product.code = productCode;
      product.trademarkName = businessInfo.tradeName;
    } else {
      product.code = productCode;
      product.state = businessInfo.organizedState;
    }

    products.push(product);

    if (productCode === PRODUCT_CODES.incAnnualCompliance) {
      products.push({
        code: PRODUCT_CODES.incAnnualReport,
        state: businessInfo.organizedState,
      });
    }
  });

  packageInfo?.linkedProducts.forEach((productCode) => {
    if (businessInfo.organizedState !== 'ME') {
      products.push({
        code: productCode,
        state: businessInfo.organizedState,
      });
    }
  });

  return {
    products,
    customer: {
      firstName: businessInfo.representativeFirstName.trim(),
      lastName: businessInfo.representativeLastName.trim(),
      email: businessInfo.customerEmail,
      phone: businessInfo.customerPhone.replace(/\D/g, ''),
    },
    order: {
      campaignId: businessInfo.campaignId,
      processingOption:
        businessInfo.processingOption || PROCESSING_CODES.standard,
    },
  };
};

export const fillOrderBodyWithSourceData = (orderBody, device, salePageURL) => {
  if (device) {
    orderBody.order.device = device;
  }

  if (salePageURL) {
    orderBody.order.salePageURL = salePageURL;
  }

  if (restoreLocalValue(STORAGE_KEYS.gclid)) {
    orderBody.order.clid = restoreLocalValue(STORAGE_KEYS.gclid);
  }

  if (restoreLocalValue(STORAGE_KEYS.source)) {
    orderBody.order.source = restoreLocalValue(STORAGE_KEYS.source);
  }

  if (restoreLocalValue(STORAGE_KEYS.keyword)) {
    orderBody.order.keyword = restoreLocalValue(STORAGE_KEYS.keyword);
  }

  if (restoreLocalValue(STORAGE_KEYS.adAccount)) {
    orderBody.order.adAccount = restoreLocalValue(STORAGE_KEYS.adAccount);
  }
};

const parseLLCOrder = (data, cid) => {
  const { customer, products = [], order } = data || {};

  const productCodes = products
    .map(({ code }) => code?.code)
    .filter((code) => {
      return [
        PRODUCT_CODES.incAnnualCompliance,
        PRODUCT_CODES.incSCorp,
        PRODUCT_CODES.incCertificateOfGoodStanding,
        PRODUCT_CODES.incBusinessLicense,
        PRODUCT_CODES.incEIN,
        PRODUCT_CODES.incOperatingAgreement,
        PRODUCT_CODES.incBankingResolution,
        PRODUCT_CODES.incLLC,
        // PRODUCT_CODES.incRegisterTrademark,
        // PRODUCT_CODES.incTrademarkBasicSearch,
        // PRODUCT_CODES.incTrademarkMonitoring,
        PRODUCT_CODES.incRegisteredAgent,
        // PRODUCT_CODES.incBOI,
        PRODUCT_CODES.incRushProcessing,
        PRODUCT_CODES.incSameDayProcessing,
      ].includes(code);
    });

  const mainProduct =
    products?.find((product) => product.code?.code === PRODUCT_CODES.incLLC) ||
    {};

  const form = {
    step: 1,
    maxStep: 1,
    organizedState: mainProduct.organizedState,
    // customerFirstName: customer.firstName,
    // customerLastName: customer.lastName,
    customerEmail: customer.email,
    customerPhone: customer.phone,
  };

  if (mainProduct.companyName) {
    form.step = 2;
    form.maxStep = 2;
    form.tradeName = mainProduct.companyName;
    form.designator = mainProduct.designator;
  }

  if (mainProduct.activityPrimaryActivity) {
    form.step = 3;
    form.maxStep = 3;
    form.activityPrimaryActivity = mainProduct.activityPrimaryActivity;
    form.activitySpecificProducts = mainProduct.activitySpecificProducts;

    if (mainProduct.productDescription) {
      form.productDescription = mainProduct.productDescription;
    }

    if (mainProduct.productExtraQuestion1) {
      form.productExtraQuestion1 = mainProduct.productExtraQuestion1;
    }

    if (mainProduct.productExtraQuestion2) {
      form.productExtraQuestion2 = mainProduct.productExtraQuestion2;
    }

    if (mainProduct.productExtraQuestion3) {
      form.productExtraQuestion3 = mainProduct.productExtraQuestion3;
    }

    if (mainProduct.productExtraQuestion4) {
      form.productExtraQuestion4 = mainProduct.productExtraQuestion4;
    }
  }

  if (mainProduct.corporateAddress) {
    form.step = 4;
    form.maxStep = 4;
    form.isMailingAddressSame = true;
    form.physicalAddressAddress = mainProduct.corporateAddress.address1;
    form.physicalAddressAddress2 = mainProduct.corporateAddress.address2 || '';
    form.physicalAddressCity = mainProduct.corporateAddress.city;
    form.physicalAddressState = mainProduct.corporateAddress.state;
    form.physicalAddressZip = mainProduct.corporateAddress.zip;
    form.physicalAddressCounty = mainProduct.corporateAddress.county;
  }

  if (mainProduct?.mailingAddress?.address1) {
    if (
      mainProduct.corporateAddress.address1 !==
      mainProduct?.mailingAddress?.address1
    ) {
      form.isMailingAddressSame = false;
      form.mailingAddressAddress = mainProduct.mailingAddress.address1;
      form.mailingAddressAddress2 = mainProduct.mailingAddress.address2 || '';
      form.mailingAddressCity = mainProduct.mailingAddress.city;
      form.mailingAddressState = mainProduct.mailingAddress.state;
      form.mailingAddressZip = mainProduct.mailingAddress.zip;
      form.mailingAddressCounty = mainProduct.mailingAddress.county;
    }
  }

  if (mainProduct?.owner) {
    form.step = 5;
    form.maxStep = 5;
    form.representativeFirstName = mainProduct?.owner?.firstName;
    form.representativeMiddleName = mainProduct?.owner?.middleName;
    form.representativeLastName = mainProduct?.owner?.lastName;
    form.representativeSuffix = mainProduct?.owner?.suffixName;
    form.representativeTitle = mainProduct?.owner?.title;
  }

  if (mainProduct.members?.length) {
    form.step = 5;
    form.maxStep = 5;

    if (mainProduct.managedByMembers) {
      form.managedByMembers = true;
      form.members = Array.isArray(mainProduct.members)
        ? mainProduct.members?.map((item) => ({
            firstName: item?.firstName || '',
            middleName: item?.middleName || '',
            lastName: item?.lastName || '',
          }))
        : [];
    } else {
      form.managedByMembers = false;
      form.managers = Array.isArray(mainProduct.members)
        ? mainProduct.members?.map((item) => ({
            firstName: item?.firstName || '',
            middleName: item?.middleName || '',
            lastName: item?.lastName || '',
          }))
        : [];
    }
  }

  if (
    mainProduct.acceptCreditCardPayments ||
    mainProduct.questionsFirstLLC ||
    mainProduct.questionsHaveEmployee ||
    mainProduct.questionsStartedDoingBusiness
  ) {
    form.step = 6;
    form.maxStep = 6;
    form.acceptCreditCardPayments = mainProduct.acceptCreditCardPayments;
    form.questionsFirstLLC = mainProduct.questionsFirstLLC;
    form.questionsHaveEmployee = mainProduct.questionsHaveEmployee;
    form.questionsStartedDoingBusiness =
      mainProduct.questionsStartedDoingBusiness;
  }

  if (order?.processingOption !== PROCESSING_CODES.standard) {
    form.step = 7;
    form.maxStep = 7;
    form.processingOption = order.processingOption;
  }

  if (order.processingOption === PROCESSING_CODES.gold) {
    if (productCodes?.includes(PRODUCT_CODES.incRegisteredAgent)) {
      form.step = 8;
      form.maxStep = 8;
    }

    if (productCodes?.includes(PRODUCT_CODES.incOperatingAgreement)) {
      form.step = 9;
      form.maxStep = 9;
    }

    if (productCodes?.includes(PRODUCT_CODES.incEIN)) {
      form.step = 9;
      form.maxStep = 9;
    }

    if (productCodes?.includes(PRODUCT_CODES.incBankingResolution)) {
      form.step = 9;
      form.maxStep = 9;
    }

    if (productCodes?.includes(PRODUCT_CODES.incBusinessLicense)) {
      form.step = 10;
      form.maxStep = 10;
    }

    if (productCodes?.includes(PRODUCT_CODES.incRushProcessing)) {
      form.step = 10;
      form.maxStep = 10;
    }

    if (productCodes?.includes(PRODUCT_CODES.incSameDayProcessing)) {
      form.step = 10;
      form.maxStep = 10;
    }
  }

  if (order.processingOption === PROCESSING_CODES.platinum) {
    if (productCodes?.includes(PRODUCT_CODES.incRegisteredAgent)) {
      form.step = 8;
      form.maxStep = 8;
    }

    if (productCodes?.includes(PRODUCT_CODES.incBusinessLicense)) {
      form.step = 9;
      form.maxStep = 9;
    }

    if (productCodes?.includes(PRODUCT_CODES.incSameDayProcessing)) {
      form.step = 9;
      form.maxStep = 9;
    }
  }

  if (order.processingOption === PROCESSING_CODES.diamond) {
    if (productCodes?.includes(PRODUCT_CODES.incRegisteredAgent)) {
      form.step = 7;
      form.maxStep = 7;
    }
  }

  if (productCodes?.includes(PRODUCT_CODES.incRegisteredAgent)) {
    form.registeredAgent = true;
  }

  if (productCodes?.includes(PRODUCT_CODES.incOperatingAgreement)) {
    form.operatingAgreement = true;
  }

  if (productCodes?.includes(PRODUCT_CODES.incEIN)) {
    form.ein = true;
  }

  if (productCodes?.includes(PRODUCT_CODES.incBankingResolution)) {
    form.bankingResolution = true;
  }

  if (productCodes?.includes(PRODUCT_CODES.incBusinessLicense)) {
    form.license = true;
  }

  if (productCodes?.includes(PRODUCT_CODES.incRushProcessing)) {
    form.rush = PRODUCT_CODES.incRushProcessing;
  }

  if (productCodes?.includes(PRODUCT_CODES.incSameDayProcessing)) {
    form.rush = PRODUCT_CODES.incSameDayProcessing;
  }

  if (productCodes?.includes(PRODUCT_CODES.incAnnualCompliance)) {
    form.compliance = true;
  }

  if (productCodes?.includes(PRODUCT_CODES.incSCorp)) {
    form.sCorp = true;
  }

  if (productCodes?.includes(PRODUCT_CODES.incCertificateOfGoodStanding)) {
    form.goodStanding = true;
  }

  if (cid === 'e') {
    form.campaignId = 16;
  }

  const maxSetOfProductsFromBankingBundle =
    getMaxSetOfProductsFromBankingBundle({
      activeProductCode: PRODUCT_CODES.incLLC,
    });

  return {
    form,
    products: productCodes,
    bankingBundle: productCodes?.filter((code) =>
      maxSetOfProductsFromBankingBundle.includes(code)
    ),
    paymentStatus: mainProduct.paymentStatus,
  };
};

export const parseOrder = (productCode, data, cid) => {
  const MAP = {
    [PRODUCT_CODES.incLLC]: parseLLCOrder,
  };

  const parser = MAP[productCode];

  return parser ? parser(data, cid) : undefined;
};

export const createOrderBeforePayment = (data, token) => {
  const { order, products } = data || {};
  const {
    id,
    crmOrderId,
    crmOrderStatus,
    orderStatus,
    discountCouponType,
    updated,
  } = order || {};

  return {
    id,
    crmOrderId,
    crmOrderStatus,
    orderStatus,
    discountCouponType,
    tokenWithDiscount: token,
    processingOption: null,
    products: products?.map(
      ({ id, code: { code }, paymentStatus, crmPaymentStatus }) => {
        return {
          id,
          code,
          paymentStatus,
          crmPaymentStatus,
        };
      }
    ),
    token: { token, orderUids: [id], expires: null },
    updated,
  };
};

export const getRouteByProductCode = (productCode) => {
  const MAP = {
    [PRODUCT_CODES.incLLC]: ROUTES.llcIncorporationFiling,
  };

  const route = MAP[productCode];

  return route ? route : ROUTES.home;
};
