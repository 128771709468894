export const FIELDS_KEYS = {
  step: 'step',
  maxStep: 'maxStep',
  name: 'name',
  representativeFirstName: 'representativeFirstName',
  representativeMiddleName: 'representativeMiddleName',
  representativeLastName: 'representativeLastName',
  representativeTitle: 'representativeTitle',
  representativeSuffix: 'representativeSuffix',
  customerFirstName: 'customerFirstName',
  customerMiddleName: 'customerMiddleName',
  customerLastName: 'customerLastName',
  customerPhone: 'customerPhone',
  customerEmail: 'customerEmail',
  fullName: 'fullName',
  email: 'email',
  phone: 'phone',
  subject: 'subject',
  message: 'message',
  legalName: 'legalName',
  tradeName: 'tradeName',
  altCompanyName: 'altCompanyName',
  organizedState: 'organizedState',
  activityReasonForApplying: 'activityReasonForApplying',
  activityPrimaryActivity: 'activityPrimaryActivity',
  productDescription: 'productDescription',
  activitySpecificProducts: 'activitySpecificProducts',
  productExtraQuestion: 'productExtraQuestion',
  acceptCreditCardPayments: 'acceptCreditCardPayments',
  payEmployeesViaPayroll: 'payEmployeesViaPayroll',
  questionsAppliedBefore: 'questionsAppliedBefore',
  questionsHire: 'questionsHire',
  questionsExcise: 'questionsExcise',
  questionsATF: 'questionsATF',
  questionsGambling: 'questionsGambling',
  questionsHighway: 'questionsHighway',
  payLessThan4k: 'payLessThan4k',
  annualTaxes: 'annualTaxes',
  questionsFirstLLC: 'questionsFirstLLC',
  questionsHaveEmployee: 'questionsHaveEmployee',
  questionsStartedDoingBusiness: 'questionsStartedDoingBusiness',
  previousEINNumberFirst2: 'previousEINNumberFirst2',
  previousEINNumberLast7: 'previousEINNumberLast7',
  numberOfAgricultureEmployees: 'numberOfAgricultureEmployees',
  numberOfHouseholdeEmployees: 'numberOfHouseholdeEmployees',
  numberOfOtherEmployees: 'numberOfOtherEmployees',
  processingOption: 'processingOption',
  processingUpgrade: 'processingUpgrade',
  cardNumber: 'cardNumber',
  cvc: 'cvc',
  cardMonth: 'cardMonth',
  cardYear: 'cardYear',
  firstName: 'firstName',
  lastName: 'lastName',
  middleName: 'middleName',
  billingAddress: 'billingAddress',
  billingAddressCountry: 'billingAddressCountry',
  billingAddressState: 'billingAddressState',
  billingAddressAddress1: 'billingAddressAddress1',
  billingAddressAddress2: 'billingAddressAddress2',
  billingAddressCity: 'billingAddressCity',
  billingAddressZip: 'billingAddressZip',
  registeredAgent: 'registeredAgent',
  designator: 'designator',
  physicalAddressAddress: 'physicalAddressAddress',
  physicalAddressAddress2: 'physicalAddressAddress2',
  physicalAddressCity: 'physicalAddressCity',
  physicalAddressState: 'physicalAddressState',
  physicalAddressCountry: 'physicalAddressCountry',
  physicalAddressZip: 'physicalAddressZip',
  physicalAddressCounty: 'physicalAddressCounty',
  isMailingAddressSame: 'isMailingAddressSame',
  mailingAddressAddress: 'mailingAddressAddress',
  mailingAddressAddress2: 'mailingAddressAddress2',
  mailingAddressCity: 'mailingAddressCity',
  mailingAddressState: 'mailingAddressState',
  mailingAddressCountry: 'mailingAddressCountry',
  mailingAddressZip: 'mailingAddressZip',
  mailingAddressCounty: 'mailingAddressCounty',
  managedByMembers: 'managedByMembers',
  managedByManagers: 'managedByManagers',
  members: 'members',
  managers: 'managers',
  ssn: 'ssn',
  dateOfBirth: 'dateOfBirth',
  dateOfBirthYear: 'dateOfBirthYear',
  dateOfBirthMonth: 'dateOfBirthMonth',
  dateOfBirthDay: 'dateOfBirthDay',
  rush: 'rush',
  registeredAgent: 'registeredAgent',
  boi: 'boi',
  ein: 'ein',
  license: 'license',
  trademark: 'trademark',
  compliance: 'compliance',
  sCorp: 'sCorp',
  goodStanding: 'goodStanding',
  salesTaxPermit: 'salesTaxPermit',
  certifiedCopy: 'certifiedCopy',
  operatingAgreement: 'operatingAgreement',
  bankingResolution: 'bankingResolution',
  bookkeeping: 'bookkeeping',
  agreeWithTerms: 'agreeWithTerms',
  file: 'file',
  driverLicenceOrID: 'driverLicenceOrID',
};

export const SUFFIX_OPTIONS = [
  { title: 'DDS', value: 'DDS' },
  { title: 'MD', value: 'MD' },
  { title: 'PhD', value: 'PhD' },
  { title: 'JR', value: 'JR' },
  { title: 'SR', value: 'SR' },
  { title: 'I', value: 'I' },
  { title: 'II', value: 'II' },
  { title: 'III', value: 'III' },
  { title: 'IV', value: 'IV' },
  { title: 'V', value: 'V' },
  { title: 'VI', value: 'VI' },
];

export const TITLE_OPTIONS = [
  { title: 'CEO', value: 'ceo' },
  { title: 'Executor', value: 'executor' },
  { title: 'Owner', value: 'owner' },
  { title: 'Managing Member', value: 'managing member' },
  { title: 'Managing Member/Owner', value: 'managing member/owner' },
  { title: 'President', value: 'president' },
  { title: 'Other', value: 'other' },
];

export const MONTH_OPTIONS = [
  { value: '01', title: 'January' },
  { value: '02', title: 'February' },
  { value: '03', title: 'March' },
  { value: '04', title: 'April' },
  { value: '05', title: 'May' },
  { value: '06', title: 'June' },
  { value: '07', title: 'July' },
  { value: '08', title: 'August' },
  { value: '09', title: 'September' },
  { value: '10', title: 'October' },
  { value: '11', title: 'November' },
  { value: '12', title: 'December' },
];

export const DAY_OPTIONS = Array.from({ length: 31 }, (_, i) => {
  return { value: `${i + 1}`, title: `${i + 1}` };
});

export const ACTIVITY_REASON_OPTIONS = [
  { value: 'started_business', title: 'Started New Business' },
  { value: 'hired_employees', title: 'Hired Employees' },
  { value: 'banking_purposes', title: 'Banking Purposes' },
  { value: 'changed_organization', title: 'Changed Type of Organization' },
  { value: 'purchased_business', title: 'Purchased Business' },
];

export const DESIGNATOR_OPTIONS = [
  { value: 'LLC', title: 'LLC' },
  { value: 'L.L.C.', title: 'L.L.C.' },
  { value: 'Limited Liability Company', title: 'Limited Liability Company' },
  { value: 'Limited Liability Co.', title: 'Limited Liability Co.' },
  { value: 'LTD Liability Company', title: 'LTD Liability Company' },
];

export const STATE_MAP = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  // AA: 'Armed Forces Americas',
  // AE: 'Armed Forces Europe, Canada, Africa and Middle East',
  // AP: 'Armed Forces Pacific',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  // FM: 'Federated States of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  // MH: 'Republic of Marshall Islands',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const STATE_OPTIONS = Object.entries(STATE_MAP).map(([key, value]) => {
  return {
    title: value,
    value: key,
  };
});
