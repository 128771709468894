import { ICONS_ALIASES } from '_constants/icons';

export const LLC_INCORPORATION_FILING_STEPS = [
  {
    iconAlias: ICONS_ALIASES.filingCalendar,
    title: 'Select state',
    children: [],
  },
  {
    iconAlias: ICONS_ALIASES.filingWebsite,
    title: 'Business Information',
    children: [
      { title: 'Business Information' },
      { title: 'Primary Business Activity' },
      { title: 'Physical Address' },
      { title: 'Manager info' },
      { title: 'Other info' },
    ],
  },
  {
    iconAlias: ICONS_ALIASES.filingCalendar,
    title: 'Package Selection',
    children: [],
  },
  {
    iconAlias: ICONS_ALIASES.filingUpdateGear,
    title: 'Upgrades',
    children: [
      { title: 'Registered Agent' },
      // { title: 'EIN/Tax ID' },
      { title: 'Business Banking Bundle Packages' },
      { title: 'Business licenses' },
      // { title: 'Annual Compliance & Maintenance' },
      // { title: 'Beneficial Ownership Information Report' },
      { title: 'Rush Processing' },
    ],
  },
  {
    iconAlias: null,
    title: 'Checkout',
    children: [],
  },
  {
    iconAlias: null,
    title: 'Upsell',
    children: [{ title: 'Ensure Regulatory Compliance for Your New Business' }],
  },
  {
    iconAlias: null,
    title: 'Thankyou',
    children: [],
  },
];

export const LLC_INCORPORATION_FILING_STEPS_GOLD = [
  {
    iconAlias: ICONS_ALIASES.filingCalendar,
    title: 'Select state',
    children: [],
  },
  {
    iconAlias: ICONS_ALIASES.filingWebsite,
    title: 'Business Information',
    children: [
      { title: 'Business Information' },
      { title: 'Primary Business Activity' },
      { title: 'Physical Address' },
      { title: 'Manager info' },
      { title: 'Other info' },
    ],
  },
  {
    iconAlias: ICONS_ALIASES.filingCalendar,
    title: 'Package Selection',
    children: [],
  },
  {
    iconAlias: ICONS_ALIASES.filingUpdateGear,
    title: 'Upgrades',
    children: [
      { title: 'Registered Agent' },
      // { title: 'EIN/Tax ID' },
      { title: 'Business Banking Bundle Packages' },
      { title: 'Business licenses' },
      // { title: 'Annual Compliance & Maintenance' },
      // { title: 'Beneficial Ownership Information Report' },
      { title: 'Rush Processing' },
    ],
  },
  {
    iconAlias: null,
    title: 'Checkout',
    children: [],
  },
  {
    iconAlias: null,
    title: 'Upsell',
    children: [{ title: 'Ensure Regulatory Compliance for Your New Business' }],
  },
  {
    iconAlias: null,
    title: 'Thankyou',
    children: [],
  },
];

export const LLC_INCORPORATION_FILING_STEPS_PLATINUM = [
  {
    iconAlias: ICONS_ALIASES.filingCalendar,
    title: 'Select state',
    children: [],
  },
  {
    iconAlias: ICONS_ALIASES.filingWebsite,
    title: 'Business Information',
    children: [
      { title: 'Business Information' },
      { title: 'Primary Business Activity' },
      { title: 'Physical Address' },
      { title: 'Manager info' },
      { title: 'Other info' },
    ],
  },
  {
    iconAlias: ICONS_ALIASES.filingCalendar,
    title: 'Package Selection',
    children: [],
  },
  {
    iconAlias: ICONS_ALIASES.filingUpdateGear,
    title: 'Upgrades',
    children: [
      { title: 'Registered Agent' },
      { title: 'Business licenses' },
      // { title: 'Annual Compliance & Maintenance' },
      // { title: 'Beneficial Ownership Information Report' },
      { title: 'Rush Processing' },
    ],
  },
  {
    iconAlias: null,
    title: 'Checkout',
    children: [],
  },
  {
    iconAlias: null,
    title: 'Upsell',
    children: [{ title: 'Ensure Regulatory Compliance for Your New Business' }],
  },
  {
    iconAlias: null,
    title: 'Thankyou',
    children: [],
  },
];

export const LLC_INCORPORATION_FILING_STEPS_DIAMOND = [
  {
    iconAlias: ICONS_ALIASES.filingCalendar,
    title: 'Select state',
    children: [],
  },
  {
    iconAlias: ICONS_ALIASES.filingWebsite,
    title: 'Business Information',
    children: [
      { title: 'Business Information' },
      { title: 'Primary Business Activity' },
      { title: 'Physical Address' },
      { title: 'Manager info' },
      { title: 'Other info' },
    ],
  },
  {
    iconAlias: ICONS_ALIASES.filingCalendar,
    title: 'Package Selection',
    children: [],
  },
  {
    iconAlias: ICONS_ALIASES.filingUpdateGear,
    title: 'Upgrades',
    children: [
      { title: 'Registered Agent' },
      // { title: 'Annual Compliance & Maintenance' },
      // { title: 'Beneficial Ownership Information Report' },
    ],
  },
  {
    iconAlias: null,
    title: 'Checkout',
    children: [],
  },
  {
    iconAlias: null,
    title: 'Upsell',
    children: [{ title: 'Ensure Regulatory Compliance for Your New Business' }],
  },
  {
    iconAlias: null,
    title: 'Thankyou',
    children: [],
  },
];

export const LLC_INCORPORATION_FILING_BANNER = [
  {
    title: 'Which State are you incorporating in?',
    text: 'Select where you want to open your business',
  },
  {
    title: 'Business Information',
    text: 'Give us some info about your business name',
  },
  {
    title: 'Primary Business Activity',
    text: 'What does your business do?',
  },
  {
    title: 'Physical address',
    text: (
      <span>
        Where is your business located?
        <br />
        If your business will be operated from your home, use your home address
        below.
      </span>
    ),
  },
  {
    title: 'Who owns this LLC?',
    text: 'Please provide the full name of each owner of this LLC',
  },
  {
    title: 'We just need a few more details',
    text: 'A couple questions to help get you incorporated',
  },
  {
    title: 'Select a Package',
    text: 'Choose the best package that suits your business needs',
  },
  {
    title: 'Appoint a registered agent to stay compliant',
    text: `
      When incorporating an LLC, you are legally required to appoint a
      Registered Agent. In most cases we act as the official registered agent
      for LLC’s registered through Incorpified™
    `,
  },
  // {
  //   title: 'Employer Identification Number (Tax ID)',
  //   text: 'Most businesses are required to have an EIN number',
  // },
  {
    title: 'Essential Business Add-Ons',
    text: null,
  },
  {
    title: 'Business Licenses & Permits',
    text: (
      <span>
        Preliminary searches show that your business type may need to obtain
        <b>licenses</b> and/or <b>permits</b> for your business
      </span>
    ),
  },
  // {
  //   title: 'Stay Compliant with the state of ',
  // eslint-disable-next-line max-len
  //   text:'Failure to maintain compliance with the state can result in losing protection of your LLC',
  // },
  // {
  //   title: 'File Your (BOI) Beneficial Ownership Information Report',
  //   text: `
  //     The Corporate Transparency Act (CTA) requires most U.S. companies to
  //     maintain transparency with the government by submitting this report to
  //     the FinCEN.
  //   `,
  // },
  {
    title: 'Rush Processing',
    text: 'Need your LLC processed quickly? Upgrade to Rush Processing.',
  },
];

export const LLC_INCORPORATION_FILING_BANNER_GOLD = [
  {
    title: 'Which State are you incorporating in?',
    text: 'Select where you want to open your business',
  },
  {
    title: 'Business Information',
    text: 'Give us some info about your business name',
  },
  {
    title: 'Primary Business Activity',
    text: 'What does your business do?',
  },
  {
    title: 'Physical address',
    text: (
      <span>
        Where is your business located?
        <br />
        If your business will be operated from your home, use your home address
        below.
      </span>
    ),
  },
  {
    title: 'Who owns this LLC?',
    text: 'Please provide the full name of each owner of this LLC',
  },
  {
    title: 'We just need a few more details',
    text: 'A couple questions to help get you incorporated',
  },
  {
    title: 'Select a Package',
    text: 'Choose the best package that suits your business needs',
  },
  {
    title: 'Appoint a registered agent to stay compliant',
    text: `
      When incorporating an LLC, you are legally required to appoint a
      Registered Agent. In most cases we act as the official registered agent
      for LLC’s registered through Incorpified™
    `,
  },
  // {
  //   title: 'Employer Identification Number (Tax ID)',
  //   text: 'Most businesses are required to have an EIN number',
  // },
  {
    title: 'Essential Business Add-Ons',
    text: null,
  },
  {
    title: 'Business Licenses & Permits',
    text: (
      <span>
        Preliminary searches show that your business type may need to obtain{' '}
        <b>licenses</b> and/or <b>permits</b> for your business
      </span>
    ),
  },
  // {
  //   title: 'Stay Compliant with the state of ',
  // eslint-disable-next-line max-len
  //   text: 'Failure to maintain compliance with the state can result in losing protection of your LLC',
  // },
  // {
  //   title: 'File Your (BOI) Beneficial Ownership Information Report',
  //   text: `
  //     The Corporate Transparency Act (CTA) requires most U.S. companies to
  //     maintain transparency with the government by submitting this report to
  //     the FinCEN.
  //   `,
  // },
  {
    title: 'Rush Processing',
    text: 'Need your LLC processed quickly? Upgrade to Rush Processing.',
  },
];

export const LLC_INCORPORATION_FILING_BANNER_PLATINUM = [
  {
    title: 'Which State are you incorporating in?',
    text: 'Select where you want to open your business',
  },
  {
    title: 'Business Information',
    text: 'Give us some info about your business name',
  },
  {
    title: 'Primary Business Activity',
    text: 'What does your business do?',
  },
  {
    title: 'Physical address',
    text: (
      <span>
        Where is your business located?
        <br />
        If your business will be operated from your home, use your home address
        below.
      </span>
    ),
  },
  {
    title: 'Who owns this LLC?',
    text: 'Please provide the full name of each owner of this LLC',
  },
  {
    title: 'We just need a few more details',
    text: 'A couple questions to help get you incorporated',
  },
  {
    title: 'Select a Package',
    text: 'Choose the best package that suits your business needs',
  },
  {
    title: 'Appoint a registered agent to stay compliant',
    text: `
      When incorporating an LLC, you are legally required to appoint a
      Registered Agent. In most cases we act as the official registered agent
      for LLC’s registered through Incorpified™
    `,
  },
  {
    title: 'Business Licenses & Permits',
    text: (
      <span>
        Preliminary searches show that your business type may need to obtain{' '}
        <b>licenses</b> and/or <b>permits</b> for your business
      </span>
    ),
  },
  // {
  //   title: 'Stay Compliant with the state of ',
  // eslint-disable-next-line max-len
  //   text: 'Failure to maintain compliance with the state can result in losing protection of your LLC',
  // },
  // {
  //   title: 'File Your (BOI) Beneficial Ownership Information Report',
  //   text: `
  //     The Corporate Transparency Act (CTA) requires most U.S. companies to
  //     maintain transparency with the government by submitting this report to
  //     the FinCEN.
  //   `,
  // },
  {
    title: 'Rush Processing',
    text: 'Need your LLC processed quickly? Upgrade to Rush Processing.',
  },
];

export const LLC_INCORPORATION_FILING_BANNER_DIAMOND = [
  {
    title: 'Which State are you incorporating in?',
    text: 'Select where you want to open your business',
  },
  {
    title: 'Business Information',
    text: 'Give us some info about your business name',
  },
  {
    title: 'Primary Business Activity',
    text: 'What does your business do?',
  },
  {
    title: 'Physical address',
    text: (
      <span>
        Where is your business located?
        <br />
        If your business will be operated from your home, use your home address
        below.
      </span>
    ),
  },
  {
    title: 'Who owns this LLC?',
    text: 'Please provide the full name of each owner of this LLC',
  },
  {
    title: 'We just need a few more details',
    text: 'A couple questions to help get you incorporated',
  },
  {
    title: 'Select a Package',
    text: 'Choose the best package that suits your business needs',
  },
  {
    title: 'Appoint a registered agent to stay compliant',
    text: `
      When incorporating an LLC, you are legally required to appoint a
      Registered Agent. In most cases we act as the official registered agent
      for LLC’s registered through Incorpified™
    `,
  },
  // {
  //   title: 'File Your (BOI) Beneficial Ownership Information Report',
  //   text: `
  //     The Corporate Transparency Act (CTA) requires most U.S. companies to
  //     maintain transparency with the government by submitting this report to
  //     the FinCEN.
  //   `,
  // },
  // {
  //   title: 'Stay Compliant with the state of ',
  // eslint-disable-next-line max-len
  //   text: 'Failure to maintain compliance with the state can result in losing protection of your LLC',
  // },
];
