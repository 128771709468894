import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';
import GTM from 'react-gtm-module';
import union from 'lodash.union';
import { hotjar } from 'react-hotjar';

import { MainApiService } from '_services';
import { STORAGE_KEYS } from '_constants/storage';
import { FIELDS_KEYS } from '_constants/forms';
import { PAYMENT_STATUS } from '_constants/payment';
import { ROUTES } from '_constants/routes';
import { GA_EVENTS, HOTJAR_EVENTS } from '_constants/analytics';
import { PROCESSING_CODES } from '_constants/processing';
import { PRODUCT_CODES } from '_constants/products';
import { FILE_TYPES } from '_constants/file';
import { processResponse, processError } from '_helpers/api';
import { createPriceMapByProcessingCode } from '_helpers/variants';
import { sanitizeFileName } from '_helpers/file';
import { getProcessingLabel } from '_helpers/processing';

import {
  getBusinessInfoUpgradesFragment,
  getMaxStep,
  getFilingStepsDataSource,
} from '_helpers/filingFlow';

import {
  getFullSetOfProducts,
  getMaxSetOfProductsFromBankingBundle,
  evaluatePackageTransitionChanges,
  sanitizeProducts,
} from '_helpers/products';

import {
  createLeadEventBody,
  createEcommerceEventProps,
} from '_helpers/analytics';

import {
  createProductNameMap,
  createProductCategoryMap,
} from '_helpers/products';

import {
  createOrderBody,
  fillOrderBodyWithSourceData,
  createOrderBeforePayment,
  parseOrder,
  getRouteByProductCode,
} from '_helpers/order';

import {
  storeSessionValue,
  restoreSessionValue,
  clearSessionValue,
  restoreLocalValue,
} from '_helpers/storage';

import {
  refreshForm as refreshBillingInfo,
  resetForm as resetBillingInfoForm,
  createPayment,
} from '_redux/payment/slice';

import { refreshActiveProductCode } from '_redux/products/slice';

const initForm = {
  step: 0,
  maxStep: 0,
  tradeName: '',
  altCompanyName: '',
  designator: 'LLC',
  organizedState: '',
  representativeFirstName: '',
  representativeMiddleName: '',
  representativeLastName: '',
  representativeTitle: '',
  representativeSuffix: '',
  activityPrimaryActivity: '',
  activitySpecificProducts: '',
  productExtraQuestion1: '',
  productExtraQuestion2: '',
  productExtraQuestion3: '',
  productExtraQuestion4: '',
  productDescription: '',
  acceptCreditCardPayments: false,
  payEmployeesViaPayroll: false,
  questionsAppliedBefore: false,
  questionsHire: false,
  questionsExcise: false,
  questionsATF: false,
  questionsGambling: false,
  questionsHighway: false,
  payLessThan4k: false,
  annualTaxes: false,
  questionsFirstLLC: false,
  questionsHaveEmployee: false,
  questionsStartedDoingBusiness: false,
  numberOfAgricultureEmployees: '0',
  numberOfHouseholdeEmployees: '0',
  numberOfOtherEmployees: '0',
  firstDateWagesMonth: new Date().getMonth() + 1,
  firstDateWagesDay: new Date().getDate(),
  firstDateWagesYear: new Date().getFullYear(),
  firstDateWagesIsValid: true,
  physicalAddressAddress: '',
  physicalAddressAddress2: '',
  physicalAddressCity: '',
  physicalAddressState: '',
  physicalAddressCountry: 'US',
  physicalAddressZip: '',
  physicalAddressCounty: '',
  isMailingAddressSame: true,
  mailingAddressAddress: '',
  mailingAddressAddress2: '',
  mailingAddressCity: '',
  mailingAddressState: '',
  mailingAddressCountry: 'US',
  mailingAddressZip: '',
  mailingAddressCounty: '',
  managedByMembers: true,
  members: [],
  managers: [],
  customerFirstName: '',
  customerLastName: '',
  customerPhone: '',
  customerEmail: '',
  processingOption: null,
  registeredAgent: false,
  boi: false,
  ein: false,
  operatingAgreement: false,
  bankingResolution: false,
  license: false,
  trademark: false,
  compliance: false,
  sCorp: false,
  goodStanding: false,
  llckit: false,
  rush: null,
  campaignId: null,
  file: null,
};

export const pullOrder = createAsyncThunk(
  'order/pullOrder',
  async function (
    { cid, orderId, token, redirectUrl },
    { dispatch, rejectWithValue }
  ) {
    try {
      MainApiService.token = token;

      const response = await MainApiService.getOrder({ orderId });
      const result = processResponse(response);

      const mainProductCode = result?.products?.find(
        ({ code: { main } }) => main
      )?.code?.code;

      if (!mainProductCode) {
        throw new Error('Main product is missing');
      }

      dispatch(refreshActiveProductCode(mainProductCode));

      const orderBeforePayment = createOrderBeforePayment(result, token);
      const preOrder = parseOrder(mainProductCode, result, cid);

      if (!preOrder) {
        throw new Error('Parsing failed');
      }

      const { form, products, bankingBundle, paymentStatus } = preOrder;

      if (paymentStatus === PAYMENT_STATUS.success) {
        throw new Error('Order completed');
      }

      dispatch(storeOrderBeforePayment(orderBeforePayment));
      dispatch(refreshPreOrderFlag(true));
      dispatch(refreshForm(form));
      dispatch(refreshProducts(products));
      dispatch(refreshBankingBundle(bankingBundle));

      if (form?.campaignId) {
        dispatch(refreshBillingInfo({ campaignId: form?.campaignId }));
      }

      MainApiService.token = null;

      if (redirectUrl) {
        Router.push(redirectUrl);
      } else {
        const urlByMainProductCode = getRouteByProductCode(mainProductCode);

        Router.push(
          urlByMainProductCode?.includes('-filing')
            ? `${urlByMainProductCode}?step=${form.maxStep}`
            : urlByMainProductCode
        );
      }

      return orderBeforePayment;
    } catch (e) {
      MainApiService.token = null;
      Router.push(ROUTES.home);
      return rejectWithValue(null);
    }
  }
);

export const createOrder = createAsyncThunk(
  'order/createOrder',
  async function (params, { dispatch, getState, rejectWithValue }) {
    const { redirectUrl } = params || {};
    const crmSessionId = restoreLocalValue(STORAGE_KEYS.crmSessionId);
    const { general, products, order } = getState();
    const { form: businessInfo, package: packageInfo } = order;
    const { device, salePageURL } = general;
    const { activeProductCode } = products;

    const orderBody = createOrderBody(
      activeProductCode,
      businessInfo,
      packageInfo
    );

    if (!orderBody) {
      throw new Error('Cannot create order body');
    }

    fillOrderBodyWithSourceData(orderBody, device, salePageURL);

    try {
      const response = await MainApiService.createOrder({
        crmSessionId,
        body: orderBody,
      });

      const result = processResponse(response);

      dispatch(storeOrderBeforePayment(result));
      dispatch(refreshPreOrderFlag(true));

      GTM.dataLayer(createLeadEventBody(orderBody));

      if (redirectUrl) {
        Router.push(redirectUrl);
      }

      return result;
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

export const updateOrder = createAsyncThunk(
  'order/updateOrder',
  async function (params, { dispatch, getState, rejectWithValue }) {
    const { redirectUrl, sendPayment } = params || {};
    const crmSessionId = restoreLocalValue(STORAGE_KEYS.crmSessionId);
    const { general, products, order, variants } = getState();
    const { form: businessInfo, package: packageInfo, data } = order;
    const { device, salePageURL } = general;
    const { activeProductCode, items: productItems } = products;
    const { data: priceItems } = variants.prices;
    const { data: feeItems } = variants.fees;

    const productNameMap = createProductNameMap(productItems);
    const productCategoryMap = createProductCategoryMap(productItems);

    const priceMapByProcessingCode = createPriceMapByProcessingCode(
      businessInfo.processingOption,
      priceItems
    );

    const feeMapByProcessingCode = createPriceMapByProcessingCode(
      PROCESSING_CODES.standard,
      feeItems
    );

    const oldProductCodes = data.processingOption
      ? data?.products?.map(({ code }) => code)
      : [];

    const newProductCodes = [
      ...packageInfo.products,
      ...packageInfo.linkedProducts,
    ];

    const removedProductCodes = oldProductCodes.filter(
      (code) => !newProductCodes.includes(code)
    );

    const addedProductCodes = newProductCodes.filter(
      (code) => !oldProductCodes.includes(code)
    );

    if (businessInfo.processingOption && removedProductCodes.length) {
      GTM.dataLayer({
        dataLayer: {
          event: GA_EVENTS.removeFromCart,
          eventProps: createEcommerceEventProps(
            removedProductCodes,
            productNameMap,
            productCategoryMap,
            priceMapByProcessingCode,
            feeMapByProcessingCode,
            data.discountCouponType
          ),
        },
      });
    }

    if (businessInfo.processingOption && addedProductCodes.length) {
      GTM.dataLayer({
        dataLayer: {
          event: GA_EVENTS.addToCart,
          eventProps: createEcommerceEventProps(
            addedProductCodes,
            productNameMap,
            productCategoryMap,
            priceMapByProcessingCode,
            feeMapByProcessingCode,
            data.discountCouponType
          ),
        },
      });
    }

    const orderBody = createOrderBody(
      activeProductCode,
      businessInfo,
      packageInfo
    );

    if (!orderBody) {
      throw new Error('Cannot create order body');
    }

    fillOrderBodyWithSourceData(orderBody, device, salePageURL);

    if (redirectUrl?.includes(ROUTES.payment)) {
      if (hotjar.initialized()) {
        hotjar.event(HOTJAR_EVENTS.leadFormSubmitted);
      }
    }

    try {
      MainApiService.token = data?.token?.token;

      const response = await MainApiService.updateOrder({
        crmSessionId,
        body: orderBody,
      });

      MainApiService.token = null;

      const rawResult = processResponse(response);

      if (businessInfo[FIELDS_KEYS.boi] && businessInfo[FIELDS_KEYS.file]) {
        const boiProductId = rawResult?.products?.find(
          ({ code }) => code === PRODUCT_CODES.incBOI
        )?.id;

        if (boiProductId) {
          dispatch(
            uploadFile({
              token: rawResult.token?.token,
              orderId: rawResult.id,
              productId: boiProductId,
              file: businessInfo[FIELDS_KEYS.file],
              fileType: FILE_TYPES.boiId,
            })
          );
          dispatch(refreshForm({ [FIELDS_KEYS.file]: null }));
        }
      }

      const result =
        data?.discountCouponType && data?.tokenWithDiscount
          ? {
              ...rawResult,
              discountCouponType: data?.discountCouponType,
              tokenWithDiscount: data?.tokenWithDiscount,
            }
          : rawResult;

      if (businessInfo.processingOption) {
        result.processingOption = businessInfo.processingOption;
      }

      if (sendPayment) {
        dispatch(refreshOrderBeforePayment(result));
        dispatch(
          createPayment({
            redirectUrl: `${ROUTES.upsell}?${STORAGE_KEYS.packageId}=${
              getProcessingLabel(businessInfo.processingOption) ||
              businessInfo.processingOption
            }&step=0`,
          })
        );
      } else {
        dispatch(storeOrderBeforePayment(result));
      }

      if (redirectUrl === ROUTES.payment) {
        GTM.dataLayer({
          dataLayer: {
            event: GA_EVENTS.viewCart,
            eventProps: createEcommerceEventProps(
              newProductCodes,
              productNameMap,
              productCategoryMap,
              priceMapByProcessingCode,
              feeMapByProcessingCode,
              data.discountCouponType
            ),
          },
        });
        GTM.dataLayer({
          dataLayer: {
            event: GA_EVENTS.beginCheckout,
            eventProps: createEcommerceEventProps(
              newProductCodes,
              productNameMap,
              productCategoryMap,
              priceMapByProcessingCode,
              feeMapByProcessingCode,
              data.discountCouponType
            ),
          },
        });
      }

      if (redirectUrl) {
        Router.push(redirectUrl);
      }

      return result;
    } catch (e) {
      MainApiService.token = null;
      const error = processError(e);
      const { status, message } = error || {};

      if (
        status === 401 ||
        status === 403 ||
        (status === 400 && message === 'Illegal order status')
      ) {
        dispatch(resetResult());
        dispatch(resetError());
        dispatch(resetForm());
        dispatch(resetPackage());
        dispatch(refreshPreOrderFlag(false));
        dispatch(resetBillingInfoForm());
        clearSessionValue(STORAGE_KEYS.businessInfo);
        clearSessionValue(STORAGE_KEYS.packageInfo);
        clearSessionValue(STORAGE_KEYS.orderBeforePayment);
        clearSessionValue(STORAGE_KEYS.billingInfo);
        clearSessionValue(STORAGE_KEYS.billingCounter);
        clearSessionValue(STORAGE_KEYS.billingHash);
      }

      return rejectWithValue(error);
    }
  }
);

export const applyPackageBackup = createAsyncThunk(
  'order/applyPackageBackup',
  async function (params, { dispatch, getState }) {
    const { order, products } = getState();
    const { activeProductCode } = products;
    const { form: businessInfo, package: packageInfo } = order;
    const { processingOption: currentProcessingCode } = businessInfo || {};
    const { processingCode: nextProcessingCode } = params || {};

    dispatch(refreshPackageBackup(currentProcessingCode));

    const fullSetOfProductsByCurrentProcessing = getFullSetOfProducts({
      activeProductCode,
      processingCode: currentProcessingCode,
      noBundles: true,
      state: businessInfo?.organizedState,
    });

    const fullSetOfProductsByNextProcessing = getFullSetOfProducts({
      activeProductCode,
      processingCode: nextProcessingCode,
      noBundles: true,
      state: businessInfo?.organizedState,
    });

    const { notIncludedProducts, includedProducts } =
      evaluatePackageTransitionChanges({
        fullSetOfProductsByCurrentProcessing,
        fullSetOfProductsByNextProcessing,
        currentSetOfProducts: packageInfo.products,
      });

    const filingStepsDataSource = getFilingStepsDataSource({
      productCode: activeProductCode,
      processingCode: nextProcessingCode,
    });

    const calculatedMaxStep = getMaxStep(filingStepsDataSource, 3);

    const availablePackageBackup = packageInfo.backup[nextProcessingCode];

    let newSetOfProducts = union(
      fullSetOfProductsByNextProcessing.filter(
        (code) => !notIncludedProducts.includes(code)
      ),
      includedProducts
    );

    if (availablePackageBackup) {
      newSetOfProducts = union(
        availablePackageBackup.products.filter(
          (code) => !notIncludedProducts.includes(code)
        ),
        includedProducts
      );

      if (nextProcessingCode === PROCESSING_CODES.gold) {
        dispatch(
          refreshForm({
            [FIELDS_KEYS.step]: calculatedMaxStep,
            [FIELDS_KEYS.maxStep]: calculatedMaxStep,
            [FIELDS_KEYS.processingOption]: nextProcessingCode,
            ...getBusinessInfoUpgradesFragment(
              sanitizeProducts({
                processingCode: nextProcessingCode,
                setOfProducts: newSetOfProducts,
              })
            ),
          })
        );

        dispatch(
          refreshProducts(
            sanitizeProducts({
              processingCode: nextProcessingCode,
              setOfProducts: newSetOfProducts,
            })
          )
        );
      } else {
        dispatch(
          refreshForm({
            [FIELDS_KEYS.step]: calculatedMaxStep,
            [FIELDS_KEYS.maxStep]: calculatedMaxStep,
            [FIELDS_KEYS.processingOption]: nextProcessingCode,
            ...getBusinessInfoUpgradesFragment(
              union(
                sanitizeProducts({
                  processingCode: nextProcessingCode,
                  setOfProducts: newSetOfProducts,
                }),
                availablePackageBackup.bankingBundle
              )
            ),
          })
        );

        dispatch(
          refreshProducts(
            union(
              sanitizeProducts({
                processingCode: nextProcessingCode,
                setOfProducts: newSetOfProducts,
              }),
              availablePackageBackup.bankingBundle
            )
          )
        );
      }

      dispatch(refreshBankingBundle(availablePackageBackup.bankingBundle));
    } else {
      dispatch(
        refreshForm({
          [FIELDS_KEYS.step]: calculatedMaxStep,
          [FIELDS_KEYS.maxStep]: calculatedMaxStep,
          [FIELDS_KEYS.processingOption]: nextProcessingCode,
          ...getBusinessInfoUpgradesFragment(
            sanitizeProducts({
              processingCode: nextProcessingCode,
              setOfProducts: newSetOfProducts,
            })
          ),
        })
      );

      if (nextProcessingCode === PROCESSING_CODES.gold) {
        dispatch(
          refreshProducts(
            sanitizeProducts({
              processingCode: nextProcessingCode,
              setOfProducts: newSetOfProducts,
            })
          )
        );
      } else {
        dispatch(
          refreshProducts(
            union(
              sanitizeProducts({
                processingCode: nextProcessingCode,
                setOfProducts: newSetOfProducts,
              }),
              getMaxSetOfProductsFromBankingBundle({ activeProductCode })
            )
          )
        );
      }

      dispatch(
        refreshBankingBundle(
          getMaxSetOfProductsFromBankingBundle({ activeProductCode })
        )
      );
    }
  }
);

export const uploadFile = createAsyncThunk(
  'order/uploadFile',
  async function (
    { orderId, productId, file, fileType, token },
    { rejectWithValue }
  ) {
    try {
      const formData = new FormData();

      if (file?.name) {
        formData.append('file', file, sanitizeFileName(file.name));
      } else {
        formData.append('file', file);
      }

      MainApiService.token = token;

      const response = await MainApiService.uploadFile({
        orderId,
        productId,
        body: formData,
        fileType,
      });

      MainApiService.token = null;
      return processResponse(response);
    } catch (e) {
      MainApiService.token = null;
      return rejectWithValue(processError(e));
    }
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    form: {
      ...initForm,
    },
    package: {
      products: [],
      linkedProducts: [],
      bankingBundle: [],
      backup: {},
    },
    file: {
      data: null,
      error: null,
      loading: false,
    },
    preOrderFlag: false,
    data: null,
    error: null,
    loading: false,
    restored: false,
  },
  reducers: {
    refreshForm(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedBusinessInfo =
          restoreSessionValue(STORAGE_KEYS.businessInfo) || {};

        storeSessionValue(STORAGE_KEYS.businessInfo, {
          ...storedBusinessInfo,
          [activeProductCode]: {
            ...state.form,
            ...action.payload,
          },
        });
      }

      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
    restoreForm(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedBusinessInfo =
          restoreSessionValue(STORAGE_KEYS.businessInfo) || {};

        state.form = {
          ...state.form,
          ...(storedBusinessInfo[activeProductCode] || initForm),
        };
      }
    },
    resetForm(state) {
      state.form = { ...initForm };
    },
    refreshPackageBackup(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);
      const processingCode = action.payload;
      const { backup, ...currentPackage } = state.package;

      if (activeProductCode && processingCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};

        storeSessionValue(STORAGE_KEYS.packageInfo, {
          ...storedPackageInfo,
          [activeProductCode]: {
            ...storedPackageInfoByActiveCode,
            backup: {
              ...storedPackageInfoByActiveCode.backup,
              [processingCode]: {
                ...currentPackage,
              },
            },
          },
        });
      }

      state.package.backup = {
        ...backup,
        [processingCode]: {
          ...currentPackage,
        },
      };
    },
    restorePackageBackup(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};
        const storedBackup = storedPackageInfoByActiveCode?.backup || {};
        state.package.backup = storedBackup;
      }
    },
    refreshProducts(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};

        storeSessionValue(STORAGE_KEYS.packageInfo, {
          ...storedPackageInfo,
          [activeProductCode]: {
            ...storedPackageInfoByActiveCode,
            products: [...action.payload],
          },
        });
      }

      state.package.products = [...action.payload];
    },
    refreshBankingBundle(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};

        storeSessionValue(STORAGE_KEYS.packageInfo, {
          ...storedPackageInfo,
          [activeProductCode]: {
            ...storedPackageInfoByActiveCode,
            bankingBundle: [...action.payload],
          },
        });
      }

      state.package.bankingBundle = [...action.payload];
    },
    addToProducts(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};
        const storedProducts = storedPackageInfoByActiveCode?.products || [];

        storeSessionValue(STORAGE_KEYS.packageInfo, {
          ...storedPackageInfo,
          [activeProductCode]: {
            ...storedPackageInfoByActiveCode,
            products: [
              ...storedProducts.filter((code) => code !== action.payload),
              action.payload,
            ],
          },
        });
      }

      state.package.products = [
        ...state.package.products.filter((code) => code !== action.payload),
        action.payload,
      ];
    },
    removeFromProducts(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};
        const storedProducts = storedPackageInfoByActiveCode?.products || [];

        storeSessionValue(STORAGE_KEYS.packageInfo, {
          ...storedPackageInfo,
          [activeProductCode]: {
            ...storedPackageInfoByActiveCode,
            products: storedProducts.filter((code) => code !== action.payload),
          },
        });
      }

      state.package.products = state.package.products.filter(
        (code) => code !== action.payload
      );
    },
    addToBankingBundle(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};
        const storedBankingBundle =
          storedPackageInfoByActiveCode?.bankingBundle || [];

        storeSessionValue(STORAGE_KEYS.packageInfo, {
          ...storedPackageInfo,
          [activeProductCode]: {
            ...storedPackageInfoByActiveCode,
            bankingBundle: [
              ...storedBankingBundle.filter((code) => code !== action.payload),
              action.payload,
            ],
          },
        });
      }

      state.package.bankingBundle = [
        ...state.package.bankingBundle.filter(
          (code) => code !== action.payload
        ),
        action.payload,
      ];
    },
    removeFromBankingBundle(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};
        const storedBankingBundle =
          storedPackageInfoByActiveCode?.bankingBundle || [];

        storeSessionValue(STORAGE_KEYS.packageInfo, {
          ...storedPackageInfo,
          [activeProductCode]: {
            ...storedPackageInfoByActiveCode,
            bankingBundle: storedBankingBundle.filter(
              (code) => code !== action.payload
            ),
          },
        });
      }

      state.package.bankingBundle = state.package.bankingBundle.filter(
        (code) => code !== action.payload
      );
    },
    restoreProducts(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};
        const storedProducts = storedPackageInfoByActiveCode?.products || [];
        state.package.products = storedProducts;
      }
    },
    restoreBankingBundle(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};
        const storedProducts =
          storedPackageInfoByActiveCode?.bankingBundle || [];
        state.package.bankingBundle = storedProducts;
      }
    },
    refreshLinkedProducts(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};

        storeSessionValue(STORAGE_KEYS.packageInfo, {
          ...storedPackageInfo,
          [activeProductCode]: {
            ...storedPackageInfoByActiveCode,
            linkedProducts: [...action.payload],
          },
        });
      }

      state.package.linkedProducts = [...action.payload];
    },
    restoreLinkedProducts(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPackageInfo =
          restoreSessionValue(STORAGE_KEYS.packageInfo) || {};
        const storedPackageInfoByActiveCode =
          storedPackageInfo[activeProductCode] || {};
        const storedLinkedProducts =
          storedPackageInfoByActiveCode?.linkedProducts || [];
        state.package.linkedProducts = storedLinkedProducts;
      }
    },
    refreshPreOrderFlag(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPreOrderFlag =
          restoreSessionValue(STORAGE_KEYS.preOrderFlag) || {};

        storeSessionValue(STORAGE_KEYS.preOrderFlag, {
          ...storedPreOrderFlag,
          [activeProductCode]: action.payload,
        });
      }

      state.preOrderFlag = action.payload;
    },
    restorePreOrderFlag(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedPreOrderFlag =
          restoreSessionValue(STORAGE_KEYS.preOrderFlag) || {};

        if (storedPreOrderFlag[activeProductCode] !== undefined) {
          state.preOrderFlag = storedPreOrderFlag[activeProductCode];
        }
      }
    },
    refreshOrderBeforePayment(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedOrderBeforePayment =
          restoreSessionValue(STORAGE_KEYS.orderBeforePayment) || {};

        storeSessionValue(STORAGE_KEYS.orderBeforePayment, {
          ...storedOrderBeforePayment,
          [activeProductCode]: {
            ...(action.payload || {}),
          },
        });
      }

      state.data = action.payload;
    },
    storeOrderBeforePayment(_, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedOrderBeforePayment =
          restoreSessionValue(STORAGE_KEYS.orderBeforePayment) || {};

        storeSessionValue(STORAGE_KEYS.orderBeforePayment, {
          ...storedOrderBeforePayment,
          [activeProductCode]: {
            ...(action.payload || {}),
          },
        });
      }
    },
    restoreOrderBeforePayment(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedOrderBeforePayment =
          restoreSessionValue(STORAGE_KEYS.orderBeforePayment) || {};
        const storedOrderBeforePaymentByActiveCode =
          storedOrderBeforePayment[activeProductCode] || null;
        state.data = storedOrderBeforePaymentByActiveCode;
        state.restored = true;
      }
    },
    resetPackage(state) {
      state.package.products = [];
      state.package.linkedProducts = [];
    },
    resetResult(state) {
      state.data = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pullOrder.pending, (state) => {
        state.data = null;
        state.error = null;
        state.loading = true;
        state.restored = false;
      })
      .addCase(pullOrder.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(pullOrder.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createOrder.pending, (state) => {
        state.data = null;
        state.error = null;
        state.loading = true;
        state.restored = false;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateOrder.pending, (state) => {
        state.error = null;
        state.loading = true;
        state.restored = false;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(uploadFile.pending, (state) => {
        state.file.error = null;
        state.file.loading = true;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.file.data = action.payload;
        state.file.loading = false;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.file.error = action.payload;
        state.file.loading = false;
      });
  },
});

export const {
  refreshForm,
  restoreForm,
  resetForm,
  refreshPackageBackup,
  restorePackageBackup,
  addToProducts,
  removeFromProducts,
  addToBankingBundle,
  removeFromBankingBundle,
  refreshProducts,
  refreshBankingBundle,
  restoreProducts,
  restoreBankingBundle,
  refreshLinkedProducts,
  restoreLinkedProducts,
  refreshPreOrderFlag,
  restorePreOrderFlag,
  refreshOrderBeforePayment,
  storeOrderBeforePayment,
  restoreOrderBeforePayment,
  resetPackage,
  resetResult,
  resetError,
} = orderSlice.actions;

export default orderSlice.reducer;
